import React from 'react';
import gallery1 from '../../assets/images/gallery.jpg';
import gallery2 from '../../assets/images/gallery1.jpg';
import gallery3 from '../../assets/images/gallery2.jpg';
import gallery4 from '../../assets/images/gallery3.jpg';
import gallery5 from '../../assets/images/gallery4.jpg';
import gallery6 from '../../assets/images/gallery5.jpg';
import gallery7 from '../../assets/images/gallery6.jpg';
import gallery8 from '../../assets/images/gallery7.jpg';

const images = [
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
];

const Gallery = () => {
  return (
    <section className='gallery-one'>
      <div className='container'>
        <div className='row'>
          {images.map((image, index) => (
            <div
              className='col-lg-4 col-md-6 col-sm-12 gallery-item'
              key={index}
            >
              <img
                className='gallery-image'
                src={image}
                alt={`Gallery ${index + 1}`}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Gallery;
