import Footer from '../components/common/footer';
import Gallery from '../components/gallery/gallery';
import Layout from '../components/common/layout';
import NavigationMenu from '../components/common/navigationMenu';
import PageHeader from '../components/common/pageHeader';
import React from 'react';

const GalleryPage = () => {
  return (
    <Layout pageTitle='Avadhani & Associates | Gallery'>
      <NavigationMenu active='gallery' />
      <PageHeader title='Gallery' />
      <Gallery />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
